import React from 'react'
import styled from "styled-components"
import {isTablet, isLandscape, isBrowser} from "react-device-detect";

import Section from '../components/section'

const StyledSection = styled(Section)`
    height: 100vh; 
    position: relative;
    picture{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }
`

export default class Contact extends React.Component {
    render() {
        return(  
            <StyledSection id='contact' className='section'>
                {isBrowser || (isTablet && isLandscape) ? (                        
                        <picture>
                            <source srcSet="map.webp" type="image/webp" alt="pf-map"/>
                            <source srcSet="../../map.png" type="image/png" alt="pf-map"/> 
                            <img src="../../map.png" alt="pf-map"/>
                        </picture>
                    ) : (
                        <picture>
                            <source srcSet="../../small-map.webp" type="image/webp" alt="pf-map"/>
                            <source srcSet="../../small-map.png" type="image/png" alt="pf-map"/> 
                            <img src="../../small-map.png" alt="pf-map"/>
                        </picture>
                    )
                }
            </StyledSection>
        )
    }
} 