import React from 'react'
import styled from "styled-components"
import {isBrowser} from "react-device-detect";

import Section from '../components/section'
import {Col} from 'react-flexa';
import Back from '../assets/home-1.svg' 

const browser = typeof window !== 'undefined';
const Vivus  = browser? require('vivus') : undefined;
const Parallax  = browser? require('parallax-js') : undefined;
const ScrollOut  = browser? require('scroll-out') : undefined;

const StyledSection = styled(Section)`
    &.loaded{
        path{
            stroke-opacity: 1;
        }
        @media(max-width:720px){
            #home-background{
                path{
                    stroke-opacity: 0;
                }
            }
        }
    }
`

const Holder = styled(Col)`
text-align:center;
font-size:30px;
color:#ffffff;
z-index:1;
line-height: 24px;
pointer-events: all!important;
.small{
    font-size:20px;
}
div{
position: static!important;
    &[data-scroll] {
        transition: opacity .4s linear 3.75s, transform .4s cubic-bezier(.33,2.1,.73,.69) 3.75s;
        opacity: 0;
        transform: translateY(40px) scale(0.4);
        &.delay{
            transition: opacity .4s linear 4.05s, transform .4s cubic-bezier(.33,2.1,.73,.69) 4.05s;
        }
    }
    &[data-scroll="in"] {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}
svg{
    fill-opacity: 1;
    path
}
`

const Logo = styled.svg`
    max-width:180px;
    margin: 32px auto;
    position: static!important;
`
let viv1;
let viv2;
let so;
let para1;
let para2;

export default class Home extends React.Component { 
    componentDidMount() {
        document.getElementById("home").classList.add("loaded");
        if(isBrowser) { 
            viv1 = new Vivus('home-background', {duration: 130, pathTimingFunction: Vivus.EASE});
        }
        viv2 = new Vivus('home-logo', {duration: 130, pathTimingFunction: Vivus.EASE});
        so = new ScrollOut({
            targets: '#home, [data-scroll]',
            threshold: 0.5,
            once: true,

            onShown: function(el) {
            if(isBrowser) { 
                viv1.play(1);
            }

            viv2.play(1);

            document.getElementById("home").classList.remove("loaded");
            const nav = document.getElementById("nav");
            if(nav){
                nav.classList.add("showNav");
            }

            let path1 = document.querySelectorAll("#home-logo path");
            for (var i = 0; i < path1.length; i++) {
                path1[i].classList.add('show');
            }
            let path2 = document.querySelectorAll("#home-background path");
            for (var j = 0; j < path2.length; j++) {
                path2[j].classList.add('show');
            }
            },
            onHidden: function(el) {
            } 
        });
        if(isBrowser) { 
            let scene1 = document.getElementById('home-holder');
            para1 = new Parallax(scene1, {
            relativeInput: true
            });
        
            let scene2 = document.getElementById('Background');
            para2 = new Parallax(scene2, {
            relativeInput: true
            });
        }
        setTimeout(function(){
            const check = document.getElementById("menu-open");
            if(check){
            document.getElementById("menu-open").checked = true;
            }
        }, 4850); 

    }
    componentWillUnmount() {
        if(viv1){ viv1.destroy(); }
        if(viv2){ viv2.destroy(); }
        if(so){ so.teardown(); }
        if(para1){ para1.destroy(); }
        if(para2){ para2.destroy(); }
    }
    render() {
        return(   
            <StyledSection id='home' className='section' justifyContent="center" alignItems="center" background_color='#278ADB'>
                <Back id='home-background' className='background'/>
                <Holder id='home-holder'>
                    <div data-depth="0" data-scroll>
                        <span>Welcome</span><br/>
                        <span className='small'>to</span>
                    </div>
                    <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 840.54 600.13" id='home-logo' data-depth="0.15">
                        <g id="letter_f" data-name="letter f">
                            <polygon className='svg-fill' points="840.54 36.3 840.54 0.63 476.54 0.63 439.54 0.63 439.54 36.3 439.54 210.63 439.54 246.3 439.54 600.13 476.54 600.13 476.54 246.3 640.04 246.3 640.04 210.63 476.54 210.63 476.54 36.3 840.54 36.3" fill="#1e1e1e" stroke='#1e1e1e' strokeWidth="10" fillOpacity="0" strokeOpacity="0"/>
                            <path className='svg-fill' d="M120.66,93.63v.2h-.5a29.32,29.32,0,0,0,0,58.64h.5v.2H400.33v-59Z" transform="translate(-0.13)" fill="#1e1e1e" stroke='#1e1e1e' strokeWidth="10" fillOpacity="0" strokeOpacity="0"/>
                        </g>
                        <g id="letter_p" data-name="letter p">
                            <rect className='svg-fill' x="363.54" y="0.63" width="36.67" height="35.67" fill="#fff" stroke='#fff' strokeWidth="10" fillOpacity="0" strokeOpacity="0"/>
                            <path className='svg-fill' d="M363.16,210.55h-243a87.5,87.5,0,0,1,0-174.89H329.33V0H120.16a123.17,123.17,0,0,0,0,246.26h243V600.05h37V210.55Z" transform="translate(-0.13)" fill="#fff" stroke='#fff' strokeWidth="10" fillOpacity="0" strokeOpacity="0"/>
                        </g>
                    </Logo>
                    <div data-depth="0" data-scroll className='delay'>Pixel Friendly</div>
                </Holder>
            </StyledSection>
        )
    }
}