import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import {Row, Col} from 'react-flexa';
import {isBrowser} from "react-device-detect";

import Back from '../assets/blog-background4.svg'
//import MobileBack from '../assets/blog-background3.svg'
import Section from '../components/section'
import Blogcont from '../components/blogpiece'
//import Link from "../components/translinkup"
//import Link from "gatsby-plugin-transition-link/AniLink";

// const browser = typeof window !== 'undefined';
// const ScrollOut  = browser? require('scroll-out') : undefined;

const BlogSection = styled(Section)`
    .blback-bot, .blback-left, .blback-top{
        transition: transform 1s ease-out;
        transform-box: fill-box;
        transform-origin: center;
        &.stop{
            transform: translate(0vw, 0vh) rotate(0deg)!important;
        }
    }
    &[data-scroll="in"] {
        .blback-bot, .blback-left, .blback-top{
            transform: translate(0vw, 0vh) rotate(0deg);
        }
    }   
    &[data-scroll="out"] {
        .blback-bot{
            transform: translate(0vw, 20vh) rotate(180deg);
        }
        .blback-left{
            transform: translate(-20vw, 0vh) rotate(180deg);
        }
        .blback-top{
            transform: translate(0vw, -20vh) rotate(180deg);
        }
        .blback-bot.blback-alt{
            transform: translate(0vw, 20vh) rotate(-180deg);
        }
        .blback-left.blback-alt{
            transform: translate(-20vw, 0vh) rotate(-180deg);
        }
        .blback-top.blback-alt{
            transform: translate(0vw, -20vh) rotate(-180deg);
        }
    }
`

const ServiceHeader = styled(Col)`
    z-index:1;
    text-align:right;
    padding-top:20px;
    h2{
    margin-right:10%;
    font-size: 36px;
    }
    p{
    margin-right:10%;
    margin-bottom: 0px;
    font-size:22px;
    }
    @media (max-width: 960px){
        width: 100%;
        h2{
            width:90%;
            margin-right:5%; 
        }
        p{
            width:90%;
            margin-right:5%; 
        }
    }
`

const BlogHolder = styled(Row)`
    margin-top: 160px;
    margin-bottom: 100px;
    z-index: 1;
    @media (max-width:760px){
        margin-top: 60px;
        margin-bottom: 60px;
    }
`
const BlogButton = styled.div`
    margin-top: 60px;
    z-index: 1;
    button{
        cursor: pointer;
        background-color: #303239;
        color: #fff;
        font-family: inherit;
        font-size: 28px;
        border: none;
        padding: 24px 40px 20px;
        border-radius: 20px;
        border-bottom-right-radius: 0;
        transition: transform cubic-bezier(.33,2.1,.73,.69) 700ms;
        &:hover{
            transform: scale(1.2, 1.2)
        }
    }
`

// let so;
let mobile=false;

export default class Blog extends React.Component {
    //componentDidMount() {
    //     so = new ScrollOut({
    //         targets: '#blog',
    //         threshold: 0.5,
    //         once: false,
    //     });
    // }
    // componentWillUnmount() {
    //     if(so){ so.teardown(); }
    //}
    render() { 
        const blog = this.props.blog;
        return (
            <BlogSection id='blog' className='section' background_color='#5BCC99' color='#303239' justifyContent="flex-end" alignContent="flex-start">
                {isBrowser ? (
                    <Back id='team-background' className='background'/>
                ) : (
                    ''
                )}
                
                <ServiceHeader md={6} md={12}>
                    <h2>Pixel Friendly<br/>Blog</h2>
                    <p>Interested in our insight on the Web development market</p>
                </ServiceHeader>
                <Col xs={12}>
                <BlogHolder justifyContent='center'>
                    {blog.edges.map(({ node }, i) => (
                        <Blogcont
                        key= {'Blog'+i}
                        id= {'Blog'+i}
                        title = {node.data.Name}
                        image = {node.data.BlogImage}
                        date = {node.data.Date}
                        url = {node.data.url}
                        excerpt = {node.data.excerpt}
                        />
                    ))}
                    <BlogButton>
                        <Link to="/blog">
                            <button>
                                Show More
                            </button>
                        </Link>
                    </BlogButton>
                </BlogHolder>
                </Col>
            </BlogSection>
        )
    }
}