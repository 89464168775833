import React from 'react'
import styled from "styled-components"

import About from '../assets/nav-about.svg'
import Service from '../assets/nav-service.svg'
import Portfolio from '../assets/nav-portfolio.svg'
import Blog from '../assets/nav-blog.svg'
import Team from '../assets/nav-team.svg'
import Contact from '../assets/nav-contact.svg'
//import Shop from '../assets/nav-shopping.svg'

// const isBrowser = typeof window !== 'undefined';
// const $  = isBrowser? require('jquery/src/jquery') : undefined;

const NavHolder = styled.div`
    position: fixed;
    right:50%;
    z-index:5;
    bottom: 84px;
    transform: translateX(-32px);
    box-sizing: border-box;
    transition: opacity .4s linear 4.35s, transform .4s cubic-bezier(.33,2.1,.73,.69) 4.35s;
    opacity: 0;
    transform: translateY(80px) translateX(-32px) scale(0.4);
    &[data-scroll="in"] {
        opacity: 1;
        transform: translateY(0px) translateX(-32px) scale(1);
        transition-delay: 0s;
    }
    &.showNav{
        opacity: 1;
        transform: translateY(0px) translateX(-32px) scale(1);
    }
    .menu-item, .menu-open-button {
        box-shadow: 2px 2px 10px rgba(0,0,0, 0.4);
        background: #fff;
        border-radius: 100%;
        width: 64px;
        height: 64px;
        position: absolute;
        text-align: center;
        transform: translate3d(0, 0, 0);
        transition: transform ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
    }
    .nav-label{
        position: absolute;
        font-size:26px;
        text-align:center;
        width: 200px;
        color:#fff;
        transform: translateY(0) scaleY(0) translateX(-68px);
        opacity:0;
        transition: transform 400ms cubic-bezier(.33,2.1,.73,.69), opacity 400ms linear;
    }
    .menu-open-button {
        z-index: 2;
        transition-duration: 400ms;
        transform: translate3d(0, 0, 0);
        cursor: pointer;
        &:hover{
            transform: scale(1.2, 1.2) translate3d(0, 0, 0);
            transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
            ~ .label-menu{
                opacity: 1;
                transform: translateY(-40px) scaleY(1) translateX(-68px);
            }
        }
    }
    .menu-open{
        display:none;
    }
    .menu-item{
        box-shadow: none;
        transform: translate3d(0, 0, 0) scale(0.3, 0.3);
        svg{
            width: 36px;
            height: auto;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .menu-open:checked {
        ~ .menu-open-button {
            transition-duration: 200ms;
            transform: scale(0.8, 0.8) translate3d(0, 0, 0);
            &:hover{
                transform: scale(1, 1) translate3d(0, 0, 0);
                ~ .label-close{
                    opacity: 1;
                    transform: translateY(-40px) scale(1) translateX(-68px);
                }
                ~ .label-menu{
                    opacity: 0;
                    transform: translateY(0px) scaleY(0) translateX(-68px);
                }
            }
        }
        ~ .menu-item {
            box-shadow: 2px 2px 10px rgba(0,0,0, 0.4);
            transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
            &:hover{
                transition-duration: 200ms;
                transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
            }
            &.active{
                box-shadow: 0 0 0 7px rgb(39,138,219);
            }
        }
        ~ .item-1 {
            transition: transform 190ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(-240px, 0, 0);
            &:hover{
                transform: translate3d(-240px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-1{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
        ~ .item-2 {
            transition: transform 290ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(-160px, 0, 0);
            &:hover{
                transform: translate3d(-160px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-2{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
        ~ .item-3 {
            transition: transform 390ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(-80px, 0, 0);
            &:hover{
                transform: translate3d(-80px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-3{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
        ~ .item-4 {
            transition: transform 190ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(80px, 0, 0);
            &:hover{
                transform: translate3d(80px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-4{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
        ~ .item-5 {
            transition: transform 290ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(160px, 0, 0);
            &:hover{
                transform: translate3d(160px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-5{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
        ~ .item-6 {
            transition: transform 390ms ease-out 200ms, box-shadow 400ms cubic-bezier(.33,2.1,.73,.69);
            transform: translate3d(240px, 0, 0);
            &:hover{
                transform: translate3d(240px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                ~ .label-6{
                    opacity: 1;
                    transform: translateY(-40px) scaleY(1) translateX(-68px);
                }
            }
        }
    }
    .hamburger {
        width: 25px;
        height: 3px;
        background: #000;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12.5px;
        margin-top: -1.5px;
        transition: transform 200ms;
    }
    .hamburger-1 {
        transform: translate3d(0, -8px, 0);
    }
    
    .hamburger-2 {
        transform: translate3d(0, 0, 0);
    }
    
    .hamburger-3 {
        transform: translate3d(0, 8px, 0);
    }
    .menu-open:checked + .menu-open-button .hamburger-1 {
            transform: translate3d(0, 0, 0) rotate(45deg);
    }
    .menu-open:checked + .menu-open-button .hamburger-2 {
            transform: translate3d(0, 0, 0) scale(0.1, 1);
    }
    .menu-open:checked + .menu-open-button .hamburger-3 {
            transform: translate3d(0, 0, 0) rotate(-45deg);
    }
    @media(max-width:640px){
        bottom: 60px;
        .menu-open:checked {
            ~ .item-1 {
                transform: translate3d(-215px, 0, 0);
                &:hover{
                    transform: translate3d(-215px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
            ~ .item-2 {
                transform: translate3d(-140px, 0, 0);
                &:hover{
                    transform: translate3d(-140px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
            ~ .item-3 {
                transform: translate3d(-65px, 0, 0);
                &:hover{
                    transform: translate3d(-65px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
            ~ .item-4 {
                transform: translate3d(65px, 0, 0);
                &:hover{
                    transform: translate3d(65px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
            ~ .item-5 {
                transform: translate3d(140px, 0, 0);
                &:hover{
                    transform: translate3d(140px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
            ~ .item-6 {
                transform: translate3d(215px, 0, 0);
                &:hover{
                    transform: translate3d(215px, 0, 0) scaleY(1.2, 1.2) translate3d(0, 0, 0);
                }
            }
    }
    @media(max-width:500px){
        transform: translateY(80px) translateX(-22px) scale(0.4);
        &[data-scroll="in"] {
            transform: translateY(0px) translateX(-22px) scale(1);
        }
        &.showNav{
            opacity: 1;
            transform: translateY(0px) translateX(-22px) scale(1);
        }
        .menu-item{
            svg{
                width: 24px;
                height: auto;
            }
        }
        .menu-item, .menu-open-button {
            width: 44px;
            height: 44px; 
        }
        .menu-open-button {
            &:hover{
                ~ .label-menu{
                    opacity: 1;
                    transform: translateY(-40px) scale(1) translateX(-78px);
                }
            }
        }
        .menu-open:checked {
            ~ .menu-open-button {
                &:hover{
                    ~ .label-close{
                        opacity: 1;
                        transform: translateY(-40px) scale(1) translateX(-78px);
                    }
                    ~ .label-menu{
                        opacity: 0;
                        transform: translateY(0px) scaleY(0) translateX(-78px);
                    }
                }
            }
            ~ .item-1 {
                transform: translate3d(-150px, 0, 0);
                &:hover{
                    transform: translate3d(-150px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-1{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
            ~ .item-2 {
                transform: translate3d(-100px, 0, 0);
                &:hover{
                    transform: translate3d(-100px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-2{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
            ~ .item-3 {
                transform: translate3d(-50px, 0, 0);
                &:hover{
                    transform: translate3d(-50px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-3{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
            ~ .item-4 {
                transform: translate3d(50px, 0, 0);
                &:hover{
                    transform: translate3d(50px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-4{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
            ~ .item-5 {
                transform: translate3d(100px, 0, 0);
                &:hover{
                    transform: translate3d(100px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-5{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
            ~ .item-6 {
                transform: translate3d(150px, 0, 0);
                &:hover{
                    transform: translate3d(150px, 0, 0) scale(1.2, 1.2) translate3d(0, 0, 0);
                    ~ .label-6{
                        opacity: 1;
                        transform: translateY(-40px) scaleY(1) translateX(-78px);
                    }
                }
            }
    }
    @media(max-width:400px){
        .menu-item{
            svg{
                width: 28px;
                height: auto;
            }
        }
        .menu-item, .menu-open-button {
            width: 38px;
            height: 38px; 
            &.active{
                box-shadow: 0 0 0 1px rgb(39,138,219);
            }
        }
        .menu-open:checked {
            ~ .item-1 {
                transform: translate3d(-126px, 0, 0);
            }
            ~ .item-2 {
                transform: translate3d(-84px, 0, 0);
            }
            ~ .item-3 {
                transform: translate3d(-42px, 0, 0);
            }
            ~ .item-4 {
                transform: translate3d(42px, 0, 0);
            }
            ~ .item-5 {
                transform: translate3d(84px, 0, 0);
            }
            ~ .item-6 {
                transform: translate3d(126px, 0, 0);
            }
    }
`
const Holder = styled.div`
    svg{
        display:none;
    }
`

export default class Nav extends React.Component {
    // componentWillMount(){

    // }
    render() { 
        return (
            <Holder>
            <NavHolder id='nav'>
                <input type="checkbox" href="#" className="menu-open" name="menu-open" id="menu-open"/>
                <label className="menu-open-button" htmlFor="menu-open">
                    <span className="hamburger hamburger-1"></span>
                    <span className="hamburger hamburger-2"></span>
                    <span className="hamburger hamburger-3"></span>
                </label>
                <a href="#about" className="menu-item item-1" aria-label="About"><About/></a>
                <a href="#services" className="menu-item item-2" aria-label="Services"><Service/></a>
                <a href="#portfolio" className="menu-item item-3" aria-label="Portfolio"><Portfolio/></a>
                <a href="#blog" className="menu-item item-4" aria-label="Blog"><Blog/></a>
                <a href="#team" className="menu-item item-5" aria-label="Team"><Team/></a>
                <a href="#contact" className="menu-item item-6" aria-label="Contact"><Contact/></a>
                <label className="nav-label label-1">About Us</label>
                <label className="nav-label label-2">Our Services</label>
                <label className="nav-label label-3">Pixel Porfolio</label>
                <label className="nav-label label-4">Pixel Blog</label>
                <label className="nav-label label-5">Team Members</label>
                <label className="nav-label label-6">Contact Us</label>
                <label className="nav-label label-close">Close</label>
                <label className="nav-label label-menu">Menu</label>
            </NavHolder>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                <filter id="shadowed-goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                    <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
                    <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2" result="shadow" />
                    <feOffset in="shadow" dx="1" dy="1" result="shadow" />
                    <feComposite in2="shadow" in="goo" result="goo" />
                    <feComposite in2="goo" in="SourceGraphic" result="mix" />
                </filter>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                    <feComposite in2="goo" in="SourceGraphic" result="mix" />
                </filter>
                </defs>
            </svg>
            </Holder>
        )
    }
}