import React from 'react'
import styled from "styled-components"
import {Col} from 'react-flexa';
import {isBrowser} from "react-device-detect";

import Back from '../assets/team-background.svg'
import BackMobile from '../assets/team-background2.svg'
import Section from '../components/section'
import Teamcont from '../components/teampiece'

const browser = typeof window !== 'undefined';
// const ScrollOut  = browser? require('scroll-out') : undefined;

const ServiceHeader = styled(Col)`
    z-index:1;
    text-align:left;
    padding-top:20px;
    padding-bottom: 20px;
    h2{
        margin-left:10%;
        font-size: 36px;
    }
    p{
        margin-left:10%;
        margin-bottom: 0px;
        font-size:22px;
    }
`

const TeamSection = styled(Section)`
    padding-bottom: 100px;
`

// let so;
let mobile=false;

export default class Team extends React.Component {
    //componentDidMount() {
    //     so = new ScrollOut({
    //         targets: '.team-container-holder',
    //         threshold: 0.4,
    //         once: false,
    //     });
    // }
    // componentWillUnmount() {
    //     if(so){ so.teardown(); }
    //}
    render() { 
        const team = this.props.team;
        return (
            <TeamSection id='team' className='section' background_color='#4aaeff' color='#303239' alignContent="flex-start" justifyContent="center">
                {isBrowser ? (
                    <Back id='team-background' className='background'/>
                ) : (
                    ''
                )}
                <ServiceHeader xs={12}>
                    <h2>Pixel Friendly Team</h2>
                    <p>Meet the Pixel Friendly Team</p>
                </ServiceHeader>
                {team.edges.map(({ node }, i) => (
                        <Teamcont
                        key= {'Team'+i}
                        id= {'Team'+i}
                        name = {node.data.Name}
                        title = {node.data.Title}
                        header = {node.data.Header}
                        content = {node.data.Content}
                        avatar = {node.data.Avatar}
                        />
                ))}
            </TeamSection>
        )
    }
}