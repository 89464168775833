import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image' 
import {Row, Col} from 'react-flexa';
import "glider-js/glider.min.css"
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Section from '../components/section'
import Back from '../assets/portfolio-background-3.svg'

const browser = typeof window !== 'undefined';
const Glider  = browser? require('glider-js') : undefined;

const PortfolioSection = styled(Section)`
    padding-bottom:20px;
        #portfolio-background{
            .port-bac1{transition: fill 1.5s, transform .4s ;}
            .port-bac2{transition: fill 1.5s, transform .6s ;}
            .port-bac3{transition: fill 1.5s, transform 0.8s ;}
            .port-bac4{transition: fill 1.5s, transform 1s ;}
            .port-bac5{transition: fill 1.5s, transform 1.2s ;}
            .port-bac6{transition: fill 1.5s, transform 1.4s ;}
            .port-bac7{transition: fill 1.5s, transform 1.6s ;}
            .port-bac8{transition: fill 1.5s, transform 1.8s ;}
            .port-bac9{transition: fill 1.5s, transform 2s ;}
            .port-bac10{transition: fill 1.5s, transform 2.2s ;}
            .port-bac11{transition: fill 1.5s, transform 0s;}
        }
        &.ken{
            .port-bac1{fill:#191919}
            .port-bac2{fill:#0e0924}
            .port-bac3{fill:#0f0c2f}
            .port-bac4{fill:#0f0e3a}
            .port-bac5{fill:#101044}
            .port-bac6{fill:#11134f}
            .port-bac7{fill:#13165a}
            .port-bac8{fill:#151964}
            .port-bac9{fill:#171d6f}
            .port-bac10{fill:#19207a}
            .port-bac11{fill:#1b2485}
            background-color:#1b2485;
        }
        &.bee{
            .port-bac1{fill:#2a1b52}
            .port-bac2{fill:#211e63}
            .port-bac3{fill:#222475}
            .port-bac4{fill:#242c86}
            .port-bac5{fill:#293597}
            .port-bac6{fill:#2b3da8}
            .port-bac7{fill:#2f44ba}
            .port-bac8{fill:#334ccb}
            .port-bac9{fill:#3654dc}
            .port-bac10{fill:#3a5cee}
            .port-bac11{fill:#3d64ff}
            background-color:#3d64ff;
        }
        &.eezi{
            .port-bac1{fill:#3d2318}
            .port-bac2{fill:#4e231c}
            .port-bac3{fill:#602721}
            .port-bac4{fill:#712a25}
            .port-bac5{fill:#822e2b}
            .port-bac6{fill:#93312f}
            .port-bac7{fill:#a53433}
            .port-bac8{fill:#b6383a}
            .port-bac9{fill:#c73d41}
            .port-bac10{fill:#d94249}
            .port-bac11{fill:#ea4651}
            background-color:#ea4651;
        }
        &.cryo{
            .port-bac1{fill:#10103d}
            .port-bac2{fill:#121f4e}
            .port-bac3{fill:#152e60}
            .port-bac4{fill:#173d71}
            .port-bac5{fill:#1a4c82}
            .port-bac6{fill:#1c5b93}
            .port-bac7{fill:#1e69a5}
            .port-bac8{fill:#2178b6}
            .port-bac9{fill:#2387c7}
            .port-bac10{fill:#2696d9}
            .port-bac11{fill:#28a5ea} 
            background-color:#28a5ea;
        }
        background-color:#82d526;
        &[data-scroll="in"] {
            path{
                transform: translate(0%, 0%) scale(1);
            }
        }   
        &[data-scroll="out"] {
            path{
                transform: translate(100%, 100%) scale(0.3);
            } 
        }
`

const PortfolioHeader = styled.div`
    margin-left:12%;
    margin-top:5%;
    left:0;
    top:0;
    position: absolute;
    h2{
        font-size: 36px;
    }
    @media (max-width: 960px){
        position: relative;
        z-index:5;
    }
`

const PortfolioPiece = styled(Col)`
    height:100%;
    align-self: center;
    .glider{
        height:100%;
        overflow-x: hidden;
    }
    .glider-slide{
        opacity: 0;
        transition: opacity 1.5s;
        background-color:transparent;
        z-index:0;
    }
    .glider-slide.active{
    opacity: 1;
    transform: translate(0px);
    z-index:1;
    }
    .glider-slide.left-1{
    transform: translate(100%);
    }
    .glider-slide.right-1{
    transform: translate(-100%);
    }
    .glider-next, .glider-prev{ 
        width: auto !important;
        top: 50%;
        opacity: 1;
        transition: transform cubic-bezier(.33,2.1,.73,.69) 700ms;
        &:hover{
            transform: scale(1.2, 1.2)
        }
        svg{
            width: 50px;
            transform: scaleY(1.2);
        }
        @media(max-width:820px){
            width: 30px !important;
        }
    }
    .glider-next{
        right: 20px;
        @media(max-width:820px){
            right: 35px;
        }
    }
    .glider-prev{
        left: 20px;
        @media(max-width:820px){
            left: 5px;
        }
    }
`

const PortfolioInner = styled(Row)`
    margin: 0 !important;
    .text{
        max-width:820px;
        padding-left: 40px;
        padding-right: 80px;
    }
    .port-button{
        text-align:center;
        a{
            button{
                cursor: pointer;
                background-color: #303239;
                color:#fff;
                font-family: inherit;
                font-size: 28px;
                border: none;
                padding: 24px 60px 20px;
                border-radius: 20px;
                border-bottom-right-radius: 0;
                margin-top: 20px;
                transition: transform cubic-bezier(.33,2.1,.73,.69) 700ms;
                &:hover{
                    transform: scale(1.2, 1.2)
                }
            }
        }
    }
    @media(max-width:820px){
        .text{
            max-width:90%;
            padding-right: 40px;
            margin: auto;
        }
    }
`

let glide;
// let so;

export default class Portfolio extends React.Component {
    componentDidMount() {
        // so = new ScrollOut({
        //     targets: '#portfolio',
        //     threshold: 0.5,
        //     once: false,
        // });
        glide = new Glider(document.querySelector('.glider'), {
            slidesToShow: 1,
            scrollLock: false,
            draggable: false,
            duration: 0,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            }
        });
        document.querySelector('.glider').addEventListener('glider-slide-visible', function(event){
            const background = document.getElementById("portfolio");
            switch(event.detail.slide) {
            case 0:
                background.classList.remove("cryo");
                background.classList.remove("ken");
                break;
            case 1:
                background.classList.remove("bee");
                background.classList.add("ken");
                break;
            case 2:
                background.classList.add("eezi");
                background.classList.remove("ken");
                background.classList.remove("bee");
                break;
            case 3:
                background.classList.add("bee");
                background.classList.remove("eezi");
                background.classList.remove("cryo");
                break;
            case 4:
                background.classList.add("cryo");
                background.classList.remove("eezi");
                break;
            default:
            }
        });
    }
    componentWillUnmount() {
        if(glide){ glide.destroy(); }
        // if(so){ so.teardown(); }
    }
    render() { 
        const portfolio = this.props.portfolio;
        return (
            <PortfolioSection id='portfolio' className='section' background_color='#303239'>
                <Back className='background'/>
                <PortfolioHeader>
                    <h2>Our Work</h2>
                </PortfolioHeader>
                <PortfolioPiece xs={12} className="glider-contain" alignSelf="center">
                    <div className="glider">
                        {portfolio.edges.map(({ node }, i) => (
                            <PortfolioInner justifyContent="center" alignItems="center" key= {'Portfolio '+i}>
                                <Col xs={12} md={12} lg={6} className="portimage">
                                    <Img fluid={node.data.Image.localFiles[0].childImageSharp.fluid} alt={node.data.Name} title={node.data.Name}/>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className='text'>
                                        <h3>{node.data.Name}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: unified()
                                                .use(markdown)
                                                .use(html)
                                                .processSync(node.data.Information)
                                            }}
                                            />
                                            <div className='port-button'>
                                                <OutboundLink href={node.data.url} target="_blank"><button>Visit Site</button></OutboundLink>
                                            </div>
                                    </div>
                                </Col>
                            </PortfolioInner>
                        ))}
                    </div>
                    <button aria-label="Previous" className="glider-prev"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 477.175 477.175">
                    <g>
                        <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" fill="#FFFFFF"/>
                    </g>
                    </svg>
                    </button>
                    <button aria-label="Next" className="glider-next"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 129 129">
                    <g>
                        <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z" fill="#FFFFFF"/>
                    </g>
                    </svg>
                    </button>
                </PortfolioPiece>
            </PortfolioSection>
        )
    }
}