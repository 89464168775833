import styled from "styled-components"
import {Row} from 'react-flexa';

const Section = styled(Row)`
  scroll-snap-align: start;
  position: relative;
  overflow:hidden;
  min-height:100vh;
  background-color: ${props => props.background_color || '#333333'};
  color: ${props => props.color || '#ffffff'};
  >.background{
    position:absolute;
    width:100%;
    height:100%;
  }
`
export default Section;