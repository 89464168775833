import React from 'react'
import styled, {keyframes} from "styled-components"
import {Col} from 'react-flexa';
import Img from 'gatsby-image' 
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`
const ticker = keyframes`
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(5deg);
    }
    15% {
        transform: rotate(-5deg);
    }
    20% {
        transform: rotate(10deg);
    }
    35% {
        transform: rotate(-9deg);
    }
    50% {
        transform: rotate(10deg);
    }
    70% {
        transform: rotate(3deg);
    }
    85% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
`

const Section = styled(Col)`
    position: relative;
    height: 360px;
    .servicerotate{
        transform: rotate(4.5deg);
        height: 100%;
    }
    .servicecolor{
        height: 100%;
        overflow:hidden;
        background-color: ${props => props.background_color || '#333333'};
        border-radius: 20px;
        backface-visibility: hidden;
        transition: border-radius 1s linear 1.5s;
    }
    @media (max-width: 960px) {
        margin: 20px auto 20px;
        max-width: 80%;
        &.Service-top-1, &.Service-bot-1{
            .servicecolor{
                background-color: ${props => props.background_color_alt};
            }
        }
    }
    @media (max-width: 650px) {
        max-width: 90%;
        width: 90%;
        .servicerotate{
            transform: rotate(0deg);
        }
    }
`
const ServiceHeader = styled.h3`
    color: #fff;
    right: 0;
    top: -30px;
    padding: 40px 30px 8px 16px;
    position: absolute;
    background-color: #303239;
    border-radius: 14px;
    margin: 0;
    z-index: 5;
    font-size:22px;
    @media (max-width: 1376px) {
        font-size:18px;
        padding: 40px 30px 8px 16px;
    }
    @media (min-width: 960px) {
        font-size:24px;
        padding: 40px 30px 8px 16px;
    }
    @media (max-width: 600px) {
        font-size:18px;
    }
`
const ServiceFeature = styled.div`
    text-align:center;
    padding-top: 7%;
    z-index: 0;
    display: flex;
    align-items: center;
    height: 100%;
    .serviceimage{
        width:75%;
        margin: auto;
    }
    svg{
        width: 70%;
        margin: auto;
        #gear-1{
            animation: 7.5s ${rotate} linear infinite;
            transform-origin: center;
            transform-box: fill-box;
        }
        #gear-2{
            animation: 7.5s ${rotate} linear infinite;
            animation-direction: reverse;
            transform-origin: center;
            transform-box: fill-box;
        }
        #speed-o{
            transform-origin: 0% 100%;
            transform-box: fill-box;
            animation: 7.5s ${ticker} linear infinite;
        }
    }

`
const ServiceContent = styled.div`
    color: #fff;
    position: absolute;
    top: -4%;
    left: -5%;
    width: 110%;
    height: 111%;
    display: flex;
    align-items: center;
    transition: all 0.75s ease-in-out;
    background-color: ${props => props.background_color || '#333333'};
    @media (max-width: 960px) {
        &.Service-top-1, &.Service-bot-1{
                background-color: ${props => props.background_color_alt};
            &:after{
                background-color: ${props => props.background_color_alt} || '#333333'};
            }
        }
    }
    transform: translateY(200%);
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        background-color: #303239;
        position: absolute;
        top: -100%;
    }
    &:after{
        content: "";
        width: 100%;
        height: 100%;
        background-color: ${props => props.background_color || '#333333'};
        position: absolute;
        top: 100%;
    }
    p{
        width: 78%;
        font-size: 22px;
        margin: auto;
    }
    @media (max-width: 360px) {
        p{
            font-size: 20px;
        }
    }
    ${Section}:hover & { 
        transform: translateY(0%);
    }
`

// let para;

export default class ServiceBlock extends React.Component {
    // componentDidMount() {
    //     let scene = document.getElementById(this.props.className);       
    //         para = new Parallax(scene, {
    //         relativeInput: true
    //     });

    // }
    // componentWillUnmount() {
    //     if(para){ para.disable(); }
    // }
    render() { 
        return (
            <Section  sm={12} md={12} lg={4} background_color={this.props.background_color} background_color_alt={this.props.background_color_alt} gutter="16px" className={this.props.className}>
                <div className="servicecolor">
                    <div className="servicerotate">
                        <ServiceHeader>{this.props.title}</ServiceHeader>
                        <ServiceFeature className="servicefeature">
                            <Img fluid={this.props.Image.localFiles[0].childImageSharp.fluid} alt={this.props.title} title={this.props.title} className="serviceimage" loading='eager'/>
                        </ServiceFeature>
                        <ServiceContent
                            dangerouslySetInnerHTML={{
                                __html: unified()
                                .use(markdown)
                                .use(html) 
                                .processSync(this.props.content)
                            }}
                            className={this.props.className}
                            background_color={this.props.background_color}
                            background_color_alt={this.props.background_color_alt}
                            />
                    </div>
                </div>
            </Section>
        )
    }
}

