import React from 'react'
import styled, {keyframes} from "styled-components"
import {isTablet, isLandscape, isBrowser} from "react-device-detect";

import Section from '../components/section'
import {Row, Col} from 'react-flexa';
import Back from '../assets/about-1.svg' 

const browser = typeof window !== 'undefined';
const Vivus  = browser? require('vivus') : undefined;
const Parallax  = browser? require('parallax-js') : undefined;
const ScrollOut  = browser? require('scroll-out') : undefined;

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`

const Holder = styled(Col)`
    font-size:30px;
    z-index:1;
    pointer-events: all!important;
    div{
        position: static!important;
    }
`

const ExtendedSection = styled(Section)`
    min-height:100vh;
    .about_svg_paths{
    transition: all 1.2s ease-out;
    }
    &[data-scroll="in"]{
    .about_svg_paths{
        transform: rotate(0deg) translate(0px)!important;
    }
    }
    &[data-scroll="out"]{
    #about_svg_1{
        transform: rotate(-15deg) translate(-40%,-5%);
    }
    #about_svg_3{
        transform: rotate(-10deg) translate(0%, 0%);
    }
    #about_svg_4{
        transform: rotate(-1deg) translate(0%, -5%);
    }
    #about_svg_5{
        transform: rotate(1deg) translate(3%, 10%);
    }
    #about_svg_6{
        transform: rotate(-2deg) translate(5%, -10%);
    }
    #about_svg_7{
        transform: rotate(-2deg) translate(-20%, -10%);
    }
    #about_svg_8{
        transform: rotate(3deg) translate(20%, 13%);
    }

    }
  }
`

const TextHolder = styled(Col)`
    font-size:20px;
    h1{
        font-size:40px;
    }
    .sub{
        max-width: 500px;
        margin-left: 16%;
        margin-bottom: 16%;
        @media(max-width:720px){
            width:90%;
            margin:auto;
        }
    }
    .about_show{
        transition: opacity 0.8s linear, transform 0.8s cubic-bezier(.33,2.1,.73,.69);
        &[data-scroll="in"] {
            opacity: 1;
            transform: translateY(0px) scale(1);
        }   
        &[data-scroll="out"] {
            opacity: 0;
            transform: translateY(40px) scale(0.5);
        }
    }
`
const FeatureHolder = styled(Col)`
    svg{
        overflow:visible;
    }
    .sub{
        text-align:center;
        svg{
            path, rect, poly, polygon{
                &.show{
                    transition: fill-opacity 1.75s ease-in 1.75s, stroke-opacity 1.75s ease-in 1.75s;
                    fill-opacity: 1;
                    stroke-opacity: 0;
                }
            }
            max-width: 80%;
            margin: auto;
            pointer-events:all!important;
            g{
                &.svg_79{
                    .svg_subgroup{
                        transition-delay:0.2s;
                    }
                }
                &.svg_80{
                    .svg_subgroup{
                        transition-delay:0.4s;
                    }   
                }
                &.svg_81{
                    .svg_subgroup{
                        transition-delay:0.6s;
                    }   
                }
                &.svg_82{
                    .svg_subgroup{
                        transition-delay:0.8s;
                    }   
                }
                .svg_subgroup{
                    transition-duration: .6s;
                    transition-timing-function:  ease;
                }
            }
            &:hover{
                .svg_79{
                    g{
                        transform:translate(-7px,-7px) scale(1.05);
                        transition-timing-function:  cubic-bezier(.33,2.1,.73,.69);
                    }
                }
                .svg_80{
                    g{
                        transform:translate(7px,-7px) scale(1.05);
                        transition-timing-function:  cubic-bezier(.33,2.1,.73,.69);
                    }
                    
                    }
                .svg_81{
                    g{
                        transform:translate(-7px,-7px) scale(1.05);
                        transition-timing-function:  cubic-bezier(.33,2.1,.73,.69);
                    }
                    
                    }
                .svg_82{
                    g{
                        transform:translate(-7px,-7px) scale(1.05);
                        transition-timing-function:  cubic-bezier(.33,2.1,.73,.69);
                    }
                }
            }
            .about_rotate{
                animation: 5s ${rotate} linear infinite;
                transform-origin: center;
                transform-box: fill-box;
            }
            #svg_78{
                animation: 4s ${rotate} linear infinite;
            }
            #svg_77{
                animation: 6s ${rotate} linear infinite;
            }
        }
    }
`
let viv1;
let so;
let para2;

export default class About extends React.Component {
    componentDidMount() {
        if(isBrowser || isTablet && isLandscape){
            viv1 = new Vivus('about-feature', {duration: 100, pathTimingFunction: Vivus.EASE});
            viv1.stop();
        }
        so = new ScrollOut({
            targets: '#feature_holder',
            threshold: 0.3,
            once: true,
            onShown: function(el) {
                if(isBrowser) { 
                    viv1.reset();
                    viv1.play(1);
                }else{
                    let featpoly = document.querySelectorAll("#about-feature polygon");
                    for (var i = 0; i < featpoly.length; i++) {
                        featpoly[i].classList.add('stop');
                    }
                    let featrect = document.querySelectorAll("#about-feature rect");
                    for (var i = 0; i < featrect.length; i++) {
                        featrect[i].classList.add('stop');
                    }
                }
                let featpoly1 = document.querySelectorAll("#about-feature polygon");
                for (var i = 0; i < featpoly1.length; i++) {
                    featpoly1[i].classList.add('show');
                }
                let featrect1 = document.querySelectorAll("#about-feature rect");
                for (var i = 0; i < featrect1.length; i++) {
                    featrect1[i].classList.add('show');
                }
                let featpath1 = document.querySelectorAll("#about-feature path");
                for (var i = 0; i < featpath1.length; i++) {
                    featpath1[i].classList.add('show');
                }
            },
                onHidden: function(el) {
            } 
        });
        // so1 = new ScrollOut({
        //     targets: '.about_show, #about',
        //     threshold: 0.5,
        //     once: false,
        // });
        if(isBrowser){
            let scene2 = document.getElementById('about-feature');
            para2 = new Parallax(scene2, {
                relativeInput: true
            });
        }
    }
    componentWillUnmount() {
        if(viv1){ viv1.destroy(); }
        if(so){ so.teardown(); }
        // if(so1){ so1.teardown(); }
        if(para2){ para2.destroy(); }
    }
    render() {
        return(  
            <ExtendedSection id='about' className='section' justifyContent="center" alignItems="center" background_color='#303239'>
                <svg id='about-background' className='background' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 539" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <radialGradient id="radial-gradient" cx="51.64" cy="422.09" r="112.32" gradientTransform="matrix(2.22, 0, 0, -1.71, 290.67, 1041.5)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#b9dfd9" />
                            <stop offset="0.34" stopColor="#00df7e" />
                            <stop offset="1" stopColor="#78df00" />
                        </radialGradient>
                        <radialGradient id="radial-gradient-2" cx="148.95" cy="332.16" r="280.62" gradientTransform="matrix(-0.84, 0, 0, 0.65, 1025.56, 350.47)" />
                    </defs>
                    <g data-name="Layer 1">
                        <rect width="960" height="539" fill="#3e414f" />
                        <polygon points="0 278.71 0 539 960 539 960 79.92 0 278.71" fill="#303239" />
                        <g id='about_svg_1' className='about_svg_paths'>
                            <polygon points="0 416.38 0 539 307.53 539 513.67 410.67 0 416.38" fill="url(#radial-gradient)" />
                            <polygon points="128.32 539 307.53 539 513.67 410.67 128.32 539" fill="#00d405" />
                        </g>
                        <polygon id='about_svg_3' className='about_svg_paths' points="960 410.65 860.58 475.8 960 439.25 960 410.65" fill="#00d405" />
                        <polygon id='about_svg_4' className='about_svg_paths' points="567.61 62.25 549.74 39.61 584.03 38.96 567.61 62.25" fill="#3ade10" />
                        <polygon id='about_svg_5' className='about_svg_paths' points="823.88 466.81 802.15 454.67 826.61 446.18 823.88 466.81" fill="#3ade10" />
                        <polygon id='about_svg_6' className='about_svg_paths' points="906.2 77.01 913.24 56.99 932.15 71.71 906.2 77.01" fill="#3ade10" />
                        <polygon id='about_svg_7' className='about_svg_paths' points="14.74 358.78 40.01 342.48 45.64 367.55 14.74 358.78" fill="#3ade10" />
                        <polygon id='about_svg_8' className='about_svg_paths' points="554.31 461.96 543.47 443.97 569.05 445.7 554.31 461.96" fill="#3ade10" />
                    </g>
                </svg>
                <Holder xs={12}>
                    <Row>
                        <TextHolder xs={12} md={6}>
                        <div className='sub'>
                            <h1 className='about_show'>
                                Pixel Friendly<br/>
                                Digital Agency
                            </h1>
                            <p className='about_show'>Pixel Friendly is a digital agency based in Sandton, South Africa. Here we offer graphic design, responsive web development and online marketing solutions to clients.</p>
                            <p className='about_show'>As a team of passionate developers and designers who strive to produce quality interactive and functional design. We offer variety of services ranging from web development to corporate identity packages. We use code and visual design to develop a professional image and shape your brand towards recognition.</p>
                        </div>
                        </TextHolder>
                        {isBrowser || (isTablet && isLandscape) ? (
                            <FeatureHolder  xs={12} md={6} id='feature_holder'>
                                <div className='sub'>
                                    <Back/>
                                </div>
                            </FeatureHolder>
                        ) : ('')
                        }
                    </Row>
                </Holder>
            </ExtendedSection>
        )
    }
}