import React from 'react'
import styled from "styled-components"
import {Row, Col} from 'react-flexa';

import Section from '../components/section'
import Service from '../components/service'

const ServiceHeader = styled(Col)`
    h2{
    margin-left:10%;
    font-size: 36px;
        @media(max-width:720px){
            width:90%;
            margin:auto;
        }
    }
    p{
    margin-left:10%;
    margin-bottom: 0px;
        @media(max-width:720px){
            width:90%;
            margin:auto;
        }
    }
`

const ServiceBottom = styled(Col)`
`

const StyledService = styled(Section)`
    padding-bottom:100px;
`

const ServiceRow = styled(Row)`
    overflow: hidden;
    transform: rotate(-4.5deg);
    width: 104%;
    margin-left: -2%!important;
    margin-bottom: ${props => props.bottom ? "16px" : "0px"};
    .servicefeature{
        transition: transform .75s cubic-bezier(0.23, 1.68, 0.68, 0.73);
    }
    h3{
        transition: transform .75s ease-out;
    }
    .Service-top-0 .servicefeature, .Service-top-0 h3{
        transition-delay:.9s;
    }
    .Service-top-1 .servicefeature, .Service-top-1 h3{
        transition-delay:1.2s;
    }
    .Service-top-2 .servicefeature, .Service-top-2 h3{
        transition-delay:1.5s;
    }
    .Service-bot-2 .servicefeature, .Service-bot-2 h3{
        transition-delay:1.3s;
    }
    .Service-bot-1 .servicefeature, .Service-bot-1 h3{
        transition-delay:1.6s;
    }
    .Service-bot-0 .servicefeature, .Service-bot-0 h3{
        transition-delay:1.9s;
    }
    &:after{
        content: "";
    }
    &[data-scroll="in"] {
        &:after{
            transform: ${props => props.bottom ? "translateX(100%)" : "translateX(-100%)"};
            transition: ${props => props.bottom ? "transform 1s ease-in-out 0s" : "transform 1s ease-in-out .4s"};
            width: 100%;
            height: 100%;
            background-color: #303239;
            position: absolute;
            left: 0%;
        }
        .servicefeature, h3{
            transform: translateY(0%) scale(1);
        }
        h3{
            transform: translateY(0%) translateZ(0);
        }
        .servicecolor{
            border-top-right-radius: 0px;
        }
    }   
    &[data-scroll="out"] {
        &:after{
            transform: translateX(0%);
            transition: transform 0.5s;
            width: 100%;
            height: 100%;
            background-color: #303239;
            position: absolute;
            left: 0%;
        }
        .servicefeature{
            transform: translateY(100%) scale(0.5);
        }
        h3{
            transform: translateY(-150%) translateZ(0);
        }
    }
    @media (max-width: 650px) {
        transform: rotate(0deg);
    }
    @media (max-width: 1378px) {
        &[data-scroll="out"] {
            .servicefeature{
                transform: translateY(0%) scale(1);
            }
            h3{
                transform: translateY(-150%) translateZ(0);
            }
        }
    }
`


export default class Services extends React.Component {
    render() { 
        const servList1 = this.props.services1;
        const servList2 = this.props.services2;
        return (
            <StyledService id='services' className='section' background_color='#303239'>
            <ServiceHeader xs={12}>
                <h2>Services</h2>
                <p>Check out what Pixel Friendly can offer you!</p>
            </ServiceHeader>
            <ServiceBottom xs={12}>
                <ServiceRow gutter="16px" bottom="true" className='servesection'>
                    {servList1.edges.map(({ node }, i) => (
                        <Service
                            key={'Service'+i}
                            id={'Service-bot'+i}
                            className={'Service-top-'+i}
                            title={node.data.Name}
                            Image={node.data.Image}
                            content={node.data.Description}
                            background_color='#9823B2'
                            background_color_alt='#FF3F62'
                        />
                    ))}
                </ServiceRow>
                <ServiceRow gutter="16px" className='servesection'>
                    {servList2.edges.map(({ node }, i) => (
                        <Service
                            key={'Service'+i}
                            id={'Service-bot'+i}
                            className={'Service-bot-'+i}
                            title={node.data.Name}
                            Image={node.data.Image}
                            content={node.data.Description}
                            background_color='#FF3F62'
                            background_color_alt='#9823B2'
                        />
                    ))}
                </ServiceRow>
            </ServiceBottom>
            </StyledService>
        )
    }
}