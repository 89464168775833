import React from 'react'
import styled from "styled-components"
import {Col} from 'react-flexa';
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';

const Section = styled(Col)`
    position: relative;
    text-align: center;
    color:#fff;
    z-index:3;
    transition: transform 500ms cubic-bezier(0.23, 1.68, 0.68, 0.73);
    transform-origin: bottom;
    .team-avatar{
        transition: transform 500ms cubic-bezier(0.23, 1.68, 0.68, 0.73) 500ms;
        transform-origin: bottom;
    }
    &[data-scroll="in"] {
        transform: scale(1, 1);
        .team-avatar{
            transform: scale(1, 1);
        }
    }   
    &[data-scroll="out"] {
        transform: scale(0.8, 0.3);
        .team-avatar{
            transform: scale(0.8, 0);
        }
    }
    .team-container{
        max-width: 420px;
        margin: auto;
        background-color:#303239;
        position: relative;
        margin-top: 110px;
        padding-bottom:58px;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,.4);
        @media (max-width:600px){
            width: 90%;
            max-width: 90%;
        }
    }
    .mouth{
        transform-origin:center;
        transform-box: fill-box;
        transition: transform .5s ease-out;
    }
    #nick-avatar{
        .mouth{
            transform: translate(-420.35px, -536.29px) scaleY(0.2);
        }
    }
    #imraan-avatar{
        .mouth{
            transform: translate(-243.21px, -300.64px) scaleY(0.2);
        }
    }
    &:hover{
        #nick-avatar{
            .mouth{
                transform: translate(-420.35px, -536.29px) scaleY(1);
            }
        }
        #imraan-avatar{
            .mouth{
                transform: translate(-243.21px, -300.64px) scaleY(1);
            }
        }
    }
    .team-container:hover{
        .team-head {
            svg{
                transform: translateY(-45%);
            }
            #imraan-header{
                #i-head-1, #i-head-2, #i-head-3{
                    transform: translateX(0px);
                    opacity: 1;
                }
            }
            #nick-header{
                #n-head-1, #n-head-2, #n-head-3, #n-head-4{
                    transform: scale(1);
                }
            }
        }
    }
    .team-container{
        svg{
            g{
                transition: all 0.5s cubic-bezier(0.23, 1.68, 0.68, 0.73) 0.5s;
            }
        }
        #imraan-header{
            #i-head-1{
                transform: translateX(-90px);
                opacity: 0;
            }
            #i-head-2{
                transform: translateX(-90px);
                opacity: 0;
                transition-delay:0.75s
            }
            #i-head-3{
                transform: translateX(90px);
                opacity: 0;
                transition-delay:1s
            }
        }
        #nick-header{
            #n-head-1{
                transform: scaleY(0);
                transform-origin: bottom;
                transform-box: fill-box;
            }
            #n-head-2{
                transform: scale(0);
                transform-origin: 0% 100%;
                transform-box: fill-box;
                transition-delay:0.75s
            }
            #n-head-3{
                transform: scale(0);
                transform-origin: 0% 100%;
                transform-box: fill-box;
                transition-delay:1s
            }
            #n-head-4{
                transform: scale(0);
                transform-origin: bottom;
                transform-box: fill-box;
                transition-delay:1.25s
            }
        }
    }
    @media(min-width:1500px){
        flex-basis: 40%;
        max-width: 40%;
    }
    @media(max-width:960px){
        margin-bottom:20px;
    }
`
const TeamTop = styled.div`
    position: absolute;
    top: -12%;
    width:100%;
    z-index:-1;
    padding-bottom: 40px;
    p{
        margin:0px;
    }
    svg{
        transition: transform .5s cubic-bezier(0.23, 1.68, 0.68, 0.73);
    }
`
const TeamHeader = styled.div`
    padding-top: 20px;
    h3{
        font-size: 32px;
        margin: 0px;
    }
    h4{
        font-family:Josefin;
        font-weight:normal;
        font-size: 24px;
        margin: 20px 0;
    }
`
const TeamContent = styled.div`
    position: relative;
    ul{
        border-top: 1px solid rgba(255, 255, 255, .7);
        border-bottom: 1px solid rgba(255, 255, 255, .7);
        list-style: none;
        font-size: 24px;
        margin: 0;
        padding: 0;
        li{
            border-top: 1px solid rgba(255, 255, 255, .7);
            border-bottom: 1px solid rgba(255, 255, 255, .7);
            overflow:hidden;
            .flipper{
                transition: transform .5s ease-in;
                transform-style: preserve-3d;
                height: 58px;
            }
            &:hover{
                .flipper{
                    transform: rotateX(90deg); 
                }
            }
        }
    }
    .front{
        padding: 0 20px;
        transform: translateZ(29px);
        height:40px;
        padding: 8px 0px;
        .icon{
            width:28%;
            display: inline-block;
            svg{
                height: 40px;
                width: auto;
            }
        }
        .team-skill{
            width:70%;
            display: inline-block;
            vertical-align: super;
            p{
                margin: 0px;
            }
        }
        @media (max-width:760px){
            .icon{
                width:20%;
            }
            .team-skill{
                width:75%;
            }
        }
    }
    .back{
        padding: 0 20px;
        transform: rotateX(-90deg) translateZ(-29px);
        height:40px;
        padding: 16px 0px 0px;
        border-top: 1px solid rgba(255, 255, 255, .7);
        border-bottom: 1px solid rgba(255, 255, 255, .7);
        vertical-align: super;
    }
`
const TeamAvatar = styled.div`
    position: absolute;
    width: 130px;
    right: -66px;
    bottom: -62px;
`

const PortBlock = props => (
    <Section xs={12} sm={12} md={12} lg={6} className="team-container-holder">
        <div className="team-container">
            <TeamTop className="team-head" dangerouslySetInnerHTML={{
                            __html: unified()
                            .use(markdown)
                            .use(html)
                            .processSync(props.header)
                        }}
                        />
            <TeamHeader>
                <h3>{props.name}</h3>
                <h4>{props.title}</h4>
            </TeamHeader>
            <TeamContent dangerouslySetInnerHTML={{
                            __html: unified()
                            .use(markdown)
                            .use(html)
                            .processSync(props.content)
                        }}
                        />
            <TeamAvatar className="team-avatar" dangerouslySetInnerHTML={{
                            __html: unified()
                            .use(markdown)
                            .use(html)
                            .processSync(props.avatar)
                        }}
                        />
        </div>
    </Section>
)

export default PortBlock;