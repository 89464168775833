import React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"
import {isTablet, isLandscape, isBrowser} from "react-device-detect";

import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../sections/home'
import About from '../sections/about'
import Services from '../sections/services'
import Portfolio from '../sections/portfolio'
import Blog from '../sections/blog'
import Team from '../sections/team'
import Contact from '../sections/contact'
import Nav from '../components/nav'

const browser = typeof window !== 'undefined';
const ScrollOut  = browser? require('scroll-out') : undefined;
const SmoothScroll = browser? require('smooth-scroll') : undefined;

const StyledLayout = styled(Layout)`
    scroll-snap-type: y mandatory;
`

let so;
let so1;
let so2;
let scroll;
let sections;
let links;

function changeLinkState(){
  let index = sections.length;

  while(--index && window.scrollY + 50 < sections[index].offsetTop) {}
  
  links.forEach((link) => link.classList.remove('active'));
  if (index==0){
    
  }
  else{
    links[index-1].classList.add('active');
  }
  
}

export default class IndexPage extends React.Component { 


  componentDidMount(){
    scroll = new SmoothScroll('a[href*="#"]');

    sections = document.querySelectorAll('.section');
    links = document.querySelectorAll('#nav a');
    
    changeLinkState();
    window.addEventListener('scroll', changeLinkState);

    var browser = navigator.userAgent.toLowerCase();
    if (browser.indexOf('chrome') > -1) {
      //document.getElementById("#feature_holder").classList.add("chrome");
    }
    if (browser.indexOf('edge') > -1) {
      let SVGS = document.querySelectorAll("#svg_77, #svg_78, #gear-2, #gear-1, #speed-o, .blback-bot, .blback-left, .blback-top");
      for (var i = 0; i < SVGS.length; i++) {
          SVGS[i].classList.add('stop');
      }
    }

    so = new ScrollOut({
      targets: '#send, #nav',
      offset: 1,
      once: false,
    });
    let contblock = document.getElementById("cont-contain");
    so1 = new ScrollOut({
        targets: '#contact',
        threshold: 0.2,
        once: false,
        onShown: function(el) {
          contblock.classList.add("show");
        },
        onHidden: function(el) {
          contblock.classList.remove("show");
        } 
    });
    if(isBrowser) {
      so2 = new ScrollOut({
        targets: '.team-container-holder, .servesection, .about_show, #about, #blog, #portfolio, .blogIndiv',
        threshold: 0.3,
        once: true,
      });
    }
    
  }
  componentWillUnmount(){
    if(so){ so.teardown(); }
    if(so1){ so1.teardown(); }
    if(so2){ so2.teardown(); }
    if(scroll){ scroll.destroy(); }
  }
  render() {
    return(  
    <StyledLayout>
      <SEO title="South African Web Developer & Graphic Designer" keywords={['Pixel Friendly','Web Design','Graphic Design','Online Marketing','Page Speed Optimisation','SEO']} />
      <Home/>
      <About/>
      <Services services1={this.props.data.services1} services2={this.props.data.services2}/>
      <Portfolio portfolio={this.props.data.portfolio}/>
      <Blog blog={this.props.data.blog}/>
      <Team team={this.props.data.team}/>
      <Contact map={this.props.data.map} smmap={this.props.data.smallMap}/>
      
      {/* {isBrowser || (isTablet && isLandscape) ? (
        <Nav/>
      ) : (
        ''
      )} */}
      <Nav/>
    </StyledLayout>
    )
  }
} 

export const listQuery = graphql` 
  query ListQuery {
    services1: allAirtable(filter: {table: {eq: "Services"}}, limit: 3) {
        edges {
          node {
            data {
              Name
              Description
              Image{
                localFiles{
                    childImageSharp{
                        fluid(maxWidth: 600, quality: 81) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
              }
            }
          }
        }
      }
    services2: allAirtable(filter: {table: {eq: "Services"}}, limit: 3, skip: 3) {
        edges {
          node {
            data {
              Name
              Description
              Image{
                localFiles{
                    childImageSharp{
                        fluid(maxWidth: 600, quality: 81) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
              }
            }
          }
        }
      }
    portfolio: allAirtable(filter: {table: {eq: "Portfolio"}}, limit: 5, sort: {order: ASC, fields: data___order}) {
      edges{
        node{
          data{
            Name
            Information
            url
            Image{
              localFiles{
                  childImageSharp{
                      fluid(maxWidth: 900, quality: 81) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
          }
        }
      }
    }
    blog: allAirtable(filter: {table: {eq: "Blog"}}, sort: {order: DESC, fields: [data___Date]}, limit: 2) {
      edges{
        node{
          data{
            Name
            excerpt
            url
            Date (formatString: "DD MMMM YYYY")
            BlogImage{
              localFiles{
                  childImageSharp{
                      fluid(maxWidth: 312 maxHeight: 202 cropFocus: CENTER,  quality: 81) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
            }
          }
        }
      }
    }
    team: allAirtable(filter: {table: {eq: "Team"}}, limit: 3) {
      edges{
        node{
          data{
            Name
            Title
            Header
            Content
            Avatar
          }
        }
      }
    }
  }
`